import App from '@/App.vue'
import { createApp, onMounted } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import router from './router'
import '@/registerServiceWorker'
// import 'bootstrap/dist/css/bootstrap.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import '@/../public/css/app.css'
import 'sweetalert2/dist/sweetalert2.css'
import "@vueform/multiselect/themes/default.css";
import {
  fontAwesomeComponents,
  fontAwesomeIcons,
} from '@/config/fontawesome.config'
import { customComponents } from '@/config/appcomponent.config'
import baseComponents from '@/components/Base/index'
import { axiosConfig } from '@/config/axios.config'
import VueApexCharts from "vue3-apexcharts";
import './registerServiceWorker'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ElDatePicker } from 'element-plus';
import 'element-plus/dist/index.css';
// import {quillEditor} from 'vue3-quill';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// import 'element-plus/theme-chalk/el-date-picker.css';

import Swal from 'sweetalert2';
import Lodash from 'lodash'

// const Application = () => import('@/App.vue')
const app = createApp(App)
const pinia = createPinia()

pinia.use(piniaPluginPersistedState)
fontAwesomeComponents(app)
baseComponents.register(app)
customComponents.config(app)
axiosConfig.config(app)

app.use(pinia)
app.use(router)
app.use(fontAwesomeIcons)
app.use(bootstrap)
app.use(VueApexCharts);
app.use(ElDatePicker)
// app.use(quillEditor)
app.component('QuillEditor', QuillEditor)
app.use(Lodash)
app.component('VueDatePicker', VueDatePicker);
app.provide("requestStatus",false)
app.directive('focus', {
  mounted(el, binding) {
    if (binding.value) {
      el.focus();
    }
  },
  updated(el, binding) {
    if (binding.value) {
      el.focus();
    }
  }
});



window.Swal = Swal;
window.__ = Lodash;

app.mount('#app')












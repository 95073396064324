/**
 krbs_kras_fe
 12/11/23,12:16 pm
 */
'use strict';
export const route = {
  login: () => 'login',
  logout: () => 'logout',
  activateOtp:()=>'activate-otp',
  sendOtp:()=>'send-otp',
  activatePassword: () => 'activate-password',
  changePassword: () => 'change-password',
  user: () => {
    const prefix = 'user';
    return {
      prefix: () => `${prefix}`,
      authDetails: () => `${prefix}/auth-details`,
      activityLogs: () => `${prefix}/activity-logs`,
    };
  },
  otp: () => {
    const prefix = 'otp';
    return {
      prefix: () => `${prefix}`,
      getEmailList: () => `${prefix}/email/list`,
      deleteEmail: (payload) => `${prefix}/email/delete/${payload}`,
      createEmail: () => `${prefix}/email/add`,
      updateEmail: (payload) => `${prefix}/email/update/${payload}`,
      getIpList: () => `${prefix}/ip/list`,
      deleteIp: (payload) => `${prefix}/ip/delete/${payload}`,
      createIp: () => `${prefix}/ip/add`,
      updateIp: (payload) => `${prefix}/ip/update/${payload}`
    }
  },
  voucher: () => 'voucher',
  fsType: () => 'fs-type',
  category: () => 'category',
  position: () => 'position',
  account: () => 'account',
  accountType: () => 'account-type',
  transaction: () => 'transaction',
  entry: () => 'entry',
  payor: () => 'payor',
  contact_directory: () => 'contact-directory',
  province: () => 'province',
  region: () => 'region',
  barangay: () => 'barangay',
  city: () => 'city',
  commonKeys: () => 'get-common-keys',
  getKeyResource : () => `getKeyResource`,
  coa: () => {
    const prefix = 'coa';
    return {
      prefix:()=>prefix,
      list: () => `${prefix}/list`,
      create: () => `${prefix}/create`,
      update: () => `${prefix}/update`,
      referenceCode: () => `${prefix}/referenceCode`,
      getListByCategory: (payload) => `${prefix}/by-category/${payload}`,
      getListByAccountRef: (payload) => `${prefix}/by-account-ref/${payload}`,
      getParentAccount: () => `${prefix}/parent-account`,
      getCashAccount: () => `${prefix}/cash-account`,
      //TODO preview Coa
      preview : () => `krbs/${prefix}/preview-chart-of-accounts`,
    };
  },

  accountCategory: () => {
    const prefix = 'account-category';
    return {
      pref : () => prefix,
      list: () => `${prefix}`,
      create: () => `${prefix}`,
      show: (payload) => `${prefix}/${payload}`,
      update: (payload) => `${prefix}/${payload}`,
    };
  },

  transactionEntry: () => {
    const prefix = 'transaction-entry';
    return {
      pref : () => prefix,
      list : () => `${prefix}/list`,
      init : () => `${prefix}/init`,
      //create : () => `${prefix}/create`,
      show : (transactionId) => `${prefix}/show/${transactionId}`,
      update : (transactionId) => `${prefix}/update/${transactionId}`, //acting as save
      drafting:(transactionId) => `${prefix}/drafting/${transactionId}`,
      preparing: (transactionId) => `${prefix}/preparing/${transactionId}`,
      posting : (transactionId) => `${prefix}/posting/${transactionId}`,
      disregards: (transactionId) => `${prefix}/disregards/${transactionId}`,
      postingMultiple : (transactionId) => `${prefix}/postingMultiple/${transactionId}`,
      preview : ()=>"krbs/preview-transaction"
    }
  },
  draftTemplates : () => {
    const prefix = 'draft';
    return {
      prefix  : () => `${prefix}`,
      getTemplates: () => `${prefix}/getDraftTemplates`,
      getTemplate : (id) => `${prefix}/getDraftTemplate/${id}`,
      createTemplate: () => `${prefix}/createDraftTemplate`,
      updateTemplate: (id) => `${prefix}/updateDraftTemplate/${id}`,
      deleteTemplate: (id) => `${prefix}/deleteDraftTemplate/${id}`,

      getEntryTemplates: () => `${prefix}/getDraftEntryTemplate`,
      getEntryTemplate: (id) => `${prefix}/getDraftEntryTemplate/${id}`,
      createEntryTemplate: () => `${prefix}/createDraftEntryTemplate`,
      updateEntryTemplate: (id) => `${prefix}/updateDraftEntryTemplate/${id}`,
      deleteEntryTemplate: (id) => `${prefix}/deleteDraftEntryTemplate/${id}`,
    }
  },
  report : () => {
    const prefix = 'report';
    return {
      generalLedger : `${prefix}/general-ledger`,
      subsidiaryLedger : `${prefix}/subsidiary-ledger`,
      viewTransactionByRefCode : (refCode) => `${prefix}/view-transaction/${refCode}`,
    }
  }
};
export default {
  route,
};

<template>
    <template v-for="(subAcc,subAccIndex) in accounts" :key="subAcc.hash_id" class="accordion-collapse collapse">
        <tr :id="`parentAccount-${parentAccountId}`"
            :class="[parentClass,`${parentClass}`]"
            class="accordion-collapse collapse krbs-data-row">
            <td style="width: 40%" colspan="2">
                <div
                    @click="formMethods.toggle(subAcc.hash_id, `icon-${parentIcon}`)"
                    :style="`padding-left: ${childLabelMargin + 1}rem !important`"
                    class="accordion-button"
                    :role="subAcc.sub_accounts?.length > 0 ? 'button' : null"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#parentAccount-${subAcc.hash_id}`"
                    :aria-controls="`parentAccount-${subAcc.hash_id}`">
                    <span v-if="subAcc.sub_accounts?.length > 0">
                        <font-awesome-icon icon="angle-right" class="me-4" :class="[`icon-${parentIcon}`]"/>
                        <!--:class="[{ 'rotate-icon-right-to-down': isIconRotated[subAcc.hash_id], 'rotate-icon-down-to-right': !isIconRotated[subAcc.hash_id]}, `icon-${parentIcon}`]"/>-->
                    </span>
                    <span v-else>
                        <font-awesome-icon icon="square-full" class="me-4 fa-x"/>
                    </span>
                    <span class="">
                        {{ subAcc.name }}
                    </span>
                </div>
            </td>
            <td style="width: 20%!important;" colspan="1">{{ subAcc.code }}</td>
            <td style="width: 20%!important;" colspan="1">{{ subAcc.reference_code }}</td>
            <td style="width: 20%!important;" colspan="1">{{ parentAccountType }}</td>
            <td colspan="1">
              <div class="action-buttons-container">
                <base-action-buttons button-type="add" @click="formMethods.handleAccountReference(subAcc.hash_id,'account-reference')"/>
                <base-action-buttons button-type="edit" @click="formMethods.editAccount(subAcc.hash_id)"/>
              </div>
            </td>
        </tr>
        <coa-table-content
                v-if="subAcc.sub_accounts?.length > 0"
                :accounts="subAcc.sub_accounts"
                :parent-account-id="subAcc.hash_id"
                :parent-class="`${parentClass} ${subAcc.hash_id}`"
                :child-label-margin="childLabelMargin + 2"
                :parent-account-type="parentAccountType"
                :parent-icon="`${parentIcon} icon-${subAcc.hash_id}`"
        />
    </template>
</template>
<script>
import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { stringify } from 'zipson/lib'
import { immutable } from 'lodash/fp/_falseOptions'
import BaseActionButtons from "@/components/Base/BaseActionButtons.vue";
import __ from 'lodash';
import {appComposables} from '@/composables/global.composables';
import router from '@/router';
export default {
  name: 'CoaTableContent',
  components: {BaseActionButtons},
  props: {
    accounts: {
      type: Object,
      required: true,
    },

    parentAccountId: {
      type: String,
      default: 'child-component',
    },

    parentAccountType : {
      type : String,
      default: ''
    },

    childLabelMargin: {
      type: Number,
      default: (0.5 * 0.5 / 100),
    },

    parentClass: {
      type: String,
      default: '',
    },

    test: {
      type: Object,
    },

    parentIcon: {
      type: String,
      default: '',
    },
  },

  setup (props) {
    const isIconRotated = ref({})
    const formMethods = {
      async toggle (hashId) {
        await nextTick(() => {
          this.toggleCloseChildren(hashId)
        })
        // toggle icon rotation
        isIconRotated.value[hashId] = !isIconRotated.value[hashId]
      },
      toggleCloseChildren (hashId) {
        nextTick(() => {
          const elements = document.getElementsByClassName(`${hashId}`)
          for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('show')
          }
        })
      },

      handleAccountReference(actionType) {
        nextTick(()=>{
          router.push(
              {
                name: 'CreateAccount',
                query: {
                  refType: `account-reference`,
                  hashId: appComposables.encryptValue(actionType),
                },
              },
          );
        })
      },

      editAccount(hashId){
          router.push(
              {
                name: 'UpdateAccount',
                params: {
                  id: !__.isEmpty(hashId) ? `${appComposables.encryptValue(hashId)}` : '',
                },
              },
          )
      }

      // toggleIcon() {
      //   isIconRotated.value = !isIconRotated.value;
      //   const elementsToRotate = document.getElementsByClassName("rotate-icon-down-to-right");
      //   console.log('isIcon', isIconRotated);
      //   console.log(elementsToRotate);
      // }
    }
    onMounted(() => {})
    onUnmounted(() => {})
    watch(() => props.test, (test) => {
      if (test === true) {
        nextTick(() => {
          const elements = document.getElementsByClassName(`${props.parentAccountId}`)
          for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('show')
          }
        })
      }
    }, { immediate: true ,deep : true})
    return {
      formMethods,
      isIconRotated,
    }
  },
}
</script>

<style scoped>
.hidden {
    display: none;
}
</style>

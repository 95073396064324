<template>
    <div id="sidenav">
        <slot name="navigation"/>
        <slot name="content"/>
    </div>
</template>
<script>
import { onMounted, onUnmounted } from 'vue'

export default {
  name: 'AppSideNav',
  components: {},
  setup () {
    onMounted(() => {})
    onUnmounted(() => {})
    return {}
  },
}
</script>

<style scoped>

</style>

<template>
    <div id="layoutSidenav_content">
        <slot name="main"/>
        <slot name="footer"/>
    </div>
</template>
<script>
import { onMounted, onUnmounted } from 'vue'

export default {
  name: 'AppSideNavContent',
  components: {},
  setup () {
    onMounted(() => {})
    onUnmounted(() => {})
    return {}
  },
}
</script>

<style scoped>

</style>

<template>
    <footer style="width: 100%;">
        <div class="container-fluid pt-1 pb-2">
            <div class="row">
                <div class="col">
                    <p class="text-center fa-xs lh-1 mb-0"> &copy;
                      <br class="d-block d-md-none">
                      "K" LINE RORO BULK SHIP MANAGEMENT CO., LTD . ROHQ
                      <span class="d-none d-md-inline">| </span>
                      <br class="d-block d-md-none">ACCOUNTING DATABASE SYSTEM</p>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import { onMounted, onUnmounted } from 'vue'

export default {
  name: 'AppFooter',
  components: {},
  setup () {
    onMounted(() => {})
    onUnmounted(() => {})
    return {}
  },
}
</script>

<style scoped>

</style>

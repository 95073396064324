/**
 elite-jov
 2/4/24,3:04 pm
 */
import {defineStore} from 'pinia';
import axios from 'axios';
import {route} from '@/services/uri.service';
import {parse, stringify} from 'zipson/lib';
import {appComposables} from '@/composables/global.composables';
import {appAlertRequestError} from "@/composables/alert.composables";
import {shallowRef} from 'vue';



let getCommonKeyRoutes = route.commonKeys();

export const globalModule = defineStore('global-module', {
    state: () => ({
        key_users: shallowRef([]),
        key_vouchers: shallowRef([]),
        key_fs_types: shallowRef([]),
        key_categories: shallowRef([]),
        key_positions: shallowRef([]),
        key_accounts: shallowRef([]),
        key_account_types: shallowRef([]),
        key_regions: shallowRef([]),
        key_provinces: shallowRef([]),
        key_cities: shallowRef([]),
        key_barangays: shallowRef([]),
        key_contact_types: shallowRef([]),
        key_contact_categories: shallowRef([]),
        key_salutations: shallowRef([]),
        key_account_categories: shallowRef([]),
        key_payors: shallowRef([]),
        key_payment_types: shallowRef([]),
    }),


    //removing appComposable capitalize due to Vue warn looping, state is still dirty , causing pinia plugin dev tool crash the browser.
    getters: {
        getKeyUsers: (state) => state['key_users'],
        getKeyVouchers: (state) => state['key_vouchers'],
        getKeyFsTypes: (state) => state['key_fs_types'],

        /**
         * @deprecated
         */
        getKeyCategories: (state) => state['key_categories'],

        getKeyPositions: (state) => state['key_positions'],
        getKeyAccounts: (state) => state['key_accounts'],
        getKeyAccountTypes: (state) => state['key_account_types'],
        getKeyRegions: state => state['key_regions'],
        getKeyProvinces: (state) => state['key_provinces'],
        getKeyCities: (state) => state['key_cities'],
        getKeyBarangays: (state) => state['key_barangays'],
        getKeyContactTypes: (state) => state['key_contact_types'],
        getKeyContactCategories: (state) => state['key_contact_categories'],
        getKeySalutations: (state) => state['key_salutations'],
        getKeyAccountCategories: (state) => state['key_account_categories'],
        getKeyPayors: (state) => state['key_payors'],
        getKeyPaymentTypes: (state) => state['key_payment_types'],
    },

    // return payorList.map((payor) => {
    //         return {
    //           hash_id: payor.hash_id,
    //           code: payor.code,
    //           name: payor.is_company ? appComposables.ucFirst(payor.company_name) :
    //               appComposables.ucFirst(
    //                   [
    //                     payor.last_name, ', ',
    //                     payor.first_name, ' ',
    //                     payor?.middle_name?.substr(0, 1) ?? '',
    //                   ].join(''), ' '),
    //         };
    //       });

    actions: {
        getCommonKeys() {
            return axios.get(getCommonKeyRoutes).then((r) => {
                const response_data = r.data;
                console.log('ping get common key routes',response_data);
                localStorage.setItem('loaded_keys',true)
                this.key_users = response_data.data.key_users;
                this.key_vouchers = response_data.data.key_vouchers;
                this.key_fs_types = response_data.data.key_fs_types;
                this.key_categories = response_data.data.key_categories;
                this.key_positions = response_data.data.key_positions;
                this.key_accounts = response_data.data.key_accounts;
                this.key_account_types = response_data.data.key_account_types;
                this.key_regions = response_data.data.key_regions;
                this.key_provinces = response_data.data.key_provinces;
                this.key_cities = response_data.data.key_cities;
                this.key_barangays = response_data.data.key_barangays;
                this.key_contact_types = response_data.data.key_contact_types;
                this.key_contact_categories = response_data.data.key_contact_categories;
                this.key_salutations = response_data.data.key_salutations;
                this.key_account_categories = response_data.data.key_account_categories;
                this.key_payors = response_data.data.key_payors;
                this.key_payment_types = response_data.data.key_payment_types;
                return true;
            }).catch((er) => {
              appAlertRequestError(er)
              return false;
            });
        },

        /**
         *
         *
         */
        async getKeyResource(payload) {
            return axios.post(route.getKeyResource(), payload).then(
                (r) => {
                    const resCallBack = r?.data;
                    const data = resCallBack.data;
                    const meta = resCallBack.meta;
                    return data;
                }
            ).catch(
                (er) => {
                    appAlertRequestError(er)
                    return false;
                }
            )
        }
    },
    persist: {
        debug: false,
        storage: localStorage,
        paths: [
            'key_users',
            'key_vouchers',
            'key_fs_types',
            'key_categories',
            'key_positions',
            'key_accounts',
            'key_account_types',
            'key_regions',
            'key_provinces',
            'key_cities',
            'key_barangays',
            'key_contact_categories',
            'key_contact_types',
            'key_salutations',
            'key_account_categories',
            'key_payors',
            'key_payment_types',
        ],
        serializer: {
            deserialize: parse,
            serialize: stringify,
        },
        beforeRestore: (ctx) => {
            console.log(`about to restore '${ctx.store.$id}'`);
        },
        afterRestore: (ctx) => {
            console.log(`just restored '${ctx.store.$id}'`);
        },
    },
});

<template>
  <div id="sidenav-nav">
    <nav class="app-sidenav accordion app-sidenav-light" id="sidenavAccordion">
      <div class="app-sidenav-menu">
        <div class="nav">
          <div class="app-sidenav-menu-heading">Main</div>
          <router-link :to="{name : 'Dashboard'}" class="nav-link" href="#">
            <div class="app-nav-link-icon">
              <font-awesome-icon icon="chart-simple"/>
            </div>
            Dashboard
          </router-link>
          <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
             data-bs-target="#collapse-master-file" aria-expanded="false"
             aria-controls="collapse-reports">
            <div class="app-nav-link-icon">
              <i class="fas fa-columns"></i>
              <font-awesome-icon icon="columns"/>
            </div>
            Master File
            <div class="app-sidenav-collapse-arrow">
              <font-awesome-icon icon="angle-down"/>
            </div>
          </a>
          <div class="collapse" id="collapse-master-file" aria-labelledby="headingOne"
               data-bs-parent="#sidenavAccordion">
            <nav class="app-sidenav-menu-nested nav">
              <router-link
                  class="nav-link"
                  :to="{name : 'ChartOfAccountList'}">
                Chart of Accounts
              </router-link>
              <router-link
                  class="nav-link"
                  :to="{name : 'AccountFiles'}">
                Account Files
              </router-link>
              <router-link
                  class="nav-link"
                  :to="{name : 'InitialEndingBalance'}">
                Initial Ending Balance
              </router-link>
              <router-link
                  class="nav-link"
                  :to="{name : 'PayeePayorList'}">
                Payee/Payor List
              </router-link>
            </nav>
          </div>
          <!--   Transaction Module  -->
          <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
             data-bs-target="#collapse-transactions"
             aria-expanded="false"
             aria-controls="collapse-vouchers">
            <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>
            <span>Transaction</span>
            <font-awesome-icon class="app-sidenav-collapse-arrow" icon="angle-down"/>
          </a>
          <div class="collapse" id="collapse-transactions" aria-labelledby="headingOne"
               data-bs-parent="#sidenavAccordion">
            <nav class="app-sidenav-menu-nested nav">
              <router-link
                  :to="{name :'TransactionList',params:{type:'cv'}}"
                  class="nav-link" href="#">
                <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>
                Check Disbursement
              </router-link>
              <router-link
                  :to="{name :'TransactionList',params:{type:'cr'}}"
                  class="nav-link text-nowrap" href="#">
                <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>
                Cash Receipt
              </router-link>
              <router-link
                  :to="{name :'TransactionList',params:{type:'jv'}}"
                  class="nav-link" href="#">
                <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>
                Journal Voucher
              </router-link>
              <!--<a class="nav-link" href="#">-->
              <!--<font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>-->
              <!--Transactions-->
              <!--</a>-->

              <!--<a class="nav-link" href="#">-->
              <!--<font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>-->
              <!--Update Entry-->
              <!--</a>-->
            </nav>
          </div>
          <!-- Reports -->
          <div class="app-sidenav-menu-heading">Reports</div>
          <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
             data-bs-target="#collapse-reports" aria-expanded="false"
             aria-controls="collapse-reports">
            <div class="app-nav-link-icon">
              <i class="fas fa-columns"></i>
              <font-awesome-icon icon="columns"/>
            </div>
            Reports
            <div class="app-sidenav-collapse-arrow">
              <font-awesome-icon icon="angle-down"/>
            </div>
          </a>
          <div class="collapse" id="collapse-reports" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
            <nav class="app-sidenav-menu-nested nav">
                <!--              <router-link :to="{name:'JournalVoucherReports'}" class="nav-link"href="#">-->
                <!--                <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>-->
                <!--                <span style="font-size: 13px;">-->
                <!--                    Journal Voucher-->
                <!--                </span>-->
                <!--              </router-link>-->
                <!--              <router-link :to="{name:'CheckVoucherReports'}" class="nav-link"  href="#">-->
                <!--                <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>-->
                <!--                <span style="font-size: 13px;">-->
                <!--                    Check Disbursement-->
                <!--                </span>-->
                <!--              </router-link>-->
                <!--              <router-link :to="{name:'CashReceiptReports'}" class="nav-link"  href="#">-->
                <!--                <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>-->
                <!--                <span style="font-size: 13px;">-->
                <!--                    Cash Receipt-->
                <!--                </span>-->
                <!--              </router-link>-->

              <router-link :to="{name:'TrialBalance'}" class="nav-link"  href="#">
                <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>
                  <span style="font-size: 13px;">
                      Trial Balance
                  </span>
              </router-link>

              <a class="nav-link"
                   data-bs-toggle="collapse"
                   data-bs-target="#collapse-ledger-reports"
                   data-bs-parent="#sidenavAccordion">
                    <font-awesome-icon class="app-nav-link-icon" icon="file"/>
                    <span  style="font-size: 13px;">
                        Ledger Reports
                    </span>
                </a>
                <div class="collapse" id="collapse-ledger-reports" aria-labelledby="headingOne"
                     data-bs-parent="#sidenavAccordionPages" style="">
                    <nav class="app-sidenav-menu-nested nav">
<!--                      <router-link :to="{name:'JournalLedger'}" class="nav-link" href="#">-->
<!--                        <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>-->
                  <!--                        <span style="font-size: 13px;">-->
<!--                                Journal Ledger-->
<!--                            </span>-->
<!--                      </router-link>
-->
                        <router-link :to="{name:'GeneralLedger'}" class="nav-link">
                            <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>
                              <span style="font-size: 13px;">
                                            General Ledger
                                        </span>
                          </router-link>

              <!--                        <router-link :to="{name:'SubsidiaryLedger'}" class="nav-link"  href="#">-->
<!--                            <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>-->
                  <!--                            <span style="font-size: 13px;">-->
<!--                                Subsidiary Ledger-->
<!--                            </span>-->
<!--                        </router-link>
-->
                    </nav>
                </div>
<!--                -->
<!--              <router-link :to="{name:'LedgerReport'}" class="nav-link" href="#">-->
<!--                <font-awesome-icon class="app-nav-link-icon" icon="file-alt"/>-->
  <!--               <span style="font-size: 13px;">-->
<!--                    Ledger Reports-->
<!--               </span>-->
<!--              </router-link>-->
            </nav>
          </div>
          <!-- Admin -->
          <div class="app-sidenav-menu-heading">Admin</div>
          <a class="nav-link collapsed"
             href="#"
             data-bs-toggle="collapse"
             data-bs-target="#collapse-settings"
             aria-expanded="false"
             aria-controls="collapse-settings">
            <font-awesome-icon class="app-nav-link-icon" icon="gears"/>
            Settings
            <font-awesome-icon class="app-sidenav-collapse-arrow" icon="angle-down"/>
          </a>

          <div class="collapse" id="collapse-settings"
               aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
            <nav class="app-sidenav-menu-nested nav">
              <router-link
                  class="nav-link"
                  :to="{name : 'IndexUser'}">
                <font-awesome-icon class="app-nav-link-icon" icon="gear"/>
                Users
              </router-link>
              <!-- TODO Barangays -->
              <!-- TODO User Type -->
              <!-- TODO Entry Templates -->
              <!-- TODO Transaction Drafts -->
              <!--              <router-link-->
              <!--                class="nav-link"-->
              <!--                :to="{name : 'IndexAccountType'}">-->
              <!--                <font-awesome-icon class="app-nav-link-icon" icon="gear"/>-->
              <!--                Account Types-->
              <!--              </router-link>-->
              <!--              <router-link-->
              <!--                class="nav-link"-->
              <!--                :to="{name : 'IndexFsType'}">-->
              <!--                <font-awesome-icon class="app-nav-link-icon" icon="gear"/>-->
              <!--                Financial Statements-->
              <!--              </router-link>-->
              <!--              <router-link class="nav-link" :to="{name : 'IndexCategory'}">-->
              <!--                <font-awesome-icon class="app-nav-link-icon" icon="gear"/>-->
              <!--                Account Categories-->
              <!--              </router-link>-->
              <!--              <a class="nav-link" href="#">-->
              <!--                <font-awesome-icon class="app-nav-link-icon" icon="gear"/>-->
              <!--                Email Notification-->
              <!--              </a>-->
              <router-link class="nav-link" :to="{name : 'IndexOtpSettings'}">
                <font-awesome-icon class="app-nav-link-icon" icon="gear"/>
                Otp Settings
              </router-link>
              <router-link class="nav-link" :to="{name : 'IndexCompanySettings'}">
                <font-awesome-icon class="app-nav-link-icon" icon="gear"/>
                Company Settings
              </router-link>
              <!--  <a class="nav-link " href="#"-->
              <!--  data-bs-toggle="collapse"-->
              <!--  data-bs-target="#collapse-company"-->
              <!--  data-bs-parent="#sidenavAccordion"-->
              <!--  aria-controls="collapse-company">-->
              <!--  <font-awesome-icon class="app-nav-link-icon" icon="gear"/>-->
              <!--  Company-->
              <!--  <font-awesome-icon class="app-sidenav-collapse-arrow" icon="angle-down"/>-->
              <!--  </a>-->
              <!--  <div class="collapse" id="collapse-company" aria-labelledby="headingOne"-->
              <!--  data-bs-parent="#sidenavAccordionPages" style="">-->
              <!--  <nav class="app-sidenav-menu-nested nav">-->
              <!--  <a class="nav-link" href="#">Basic Details</a>-->
              <!--  <a class="nav-link" href="#">General</a>-->
              <!--  <a class="nav-link" href="#">Fiscal Period</a>-->
              <!--  &lt;!&ndash; <a class="nav-link" href="#">Header</a>&ndash;&gt;-->
              <!--  &lt;!&ndash; <a class="nav-link" href="#">Logo</a>&ndash;&gt;-->
              <!--  </nav>-->
              <!--  </div>-->
              <a class="nav-link"
                 data-bs-toggle="collapse"
                 data-bs-target="#collapse-drafts"
                 data-bs-parent="#sidenavAccordion">
                <font-awesome-icon class="app-nav-link-icon" icon="gear"/>
                Draft's
              </a>
              <div class="collapse" id="collapse-drafts"
                   aria-labelledby="headingOne"
                   data-bs-parent="#sidenavAccordionPages">
                <nav class="app-sidenav-menu-nested nav">
                  <router-link class="nav-link" :to="{name:'DraftTemplateList',params : {}}">Templates</router-link>
                </nav>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import {nextTick, onMounted, onUnmounted} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {globalModule} from "@/store/global.module";
export default {
  name: 'AppSideNavLink',
  components: {FontAwesomeIcon},
  setup() {
    const useGlobalModule = globalModule()
    const configNav = () => {
      const sidebarToggle = document.body.querySelector('#sidebarToggle');
      if (sidebarToggle) {
        // Uncomment Below to persist sidebar toggle between refreshes
        if (localStorage.getItem('app|sidebar-toggle') === true) {
          document.body.classList.toggle('app-sidenav-toggled');
        }
        sidebarToggle.addEventListener('click', event => {
          event.preventDefault();
          document.body.classList.toggle('app-sidenav-toggled');
          localStorage.setItem('app|sidebar-toggle',document.body.classList.contains('app-sidenav-toggled'));
        });
      }
    };
    /** Method  **/
    /** END Method  **/
    onMounted(() => {
      nextTick(() => {
        configNav();
      });
    });
    onUnmounted(() => {});
    return {};
  },
};
</script>
<style scoped></style>

import "@fortawesome/fontawesome-svg-core";
import  "@fortawesome/free-brands-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeLayersText,
  FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome';

import {
  faBars,faSearch,faUserLock,faTachometerAlt,faAngleDown,faColumns,
  faBookOpen,faFileAlt,faChartSimple,faGears,faGear,faBell,faFile,faArrowDown,
  faCircle,faCaretRight,faSquareFull, faAngleRight, faPlus, faLockOpen,
  faLock, faAngleUp, faEye, faEyeSlash, faReply, faArrowsUpDownLeftRight, faTag,
  faPesoSign,faFileLines,faFilePen,faList,faFileText,faAdd,faUndoAlt,faEdit,faTrashAlt,
  faX, faUpload,faCirclePlus,faTimesCircle,faPrint,
} from '@fortawesome/free-solid-svg-icons';
import {createApp} from 'vue';
import App from '@/App.vue'
export const fontAwesomeComponents = (app) =>{
  app.component('font-awesome-icon',FontAwesomeIcon);
  app.component('font-awesome-layer-text',FontAwesomeLayersText);
  app.component('font-awesome-layers',FontAwesomeLayers);
}

export const fontAwesomeIcons = () => {
  library.add(
    faBars,faSearch,faUserLock,faTachometerAlt,faAngleDown,
    faColumns,faBookOpen,faFileAlt,faChartSimple,faGears,faGear,
    faBell,faFile,faArrowDown,faCircle,faCaretRight, faSquareFull, faAngleRight,
    faPlus, faLockOpen, faLock, faAngleUp, faEye, faEyeSlash, faReply,faArrowsUpDownLeftRight, faTag,
    faPesoSign,faFileLines,faFilePen,faList,faFileText,faAdd,faUndoAlt,faEdit,faTrashAlt,
    faX, faUpload,faCirclePlus,faTimesCircle,faPrint
  )
}

export default {
  fontAwesomeComponents,
  fontAwesomeIcons
}



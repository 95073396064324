/**
 krbs_kras_fe
 11/23/23,8:40 am
 */

import {nextTick, reactive, ref} from 'vue';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
import __, {parseInt} from 'lodash';
import CryptoJS from 'crypto-js';
import {appAlert} from '@/composables/alert.composables';
import {value} from 'lodash/seq';
import {parse} from "zipson/lib";

const keyValue = `${process.env.VUE_APP_KEY_VALUE}`;
const ivKey = `${process.env.VUE_APP_KEY}`;
const exception = [
    'hash_id',
    'created_by',
    'updated_by',
    'email',
    'username',
    'password',
    'token',
    'reference_code',
    'accg_group_code',
]; //exclude hash id as a prior

export const CREATING = 0;
export const UPDATING = 1;
export const DELETING = 2;

const actions = [
    'Creating',
    'Updating',
    'Deleting',
];

export const appComposables = {
    async noPreviousBrowsing() {
        await nextTick(() => {
            window.history.pushState('', '', location.href);
            window.history.back();
            window.history.forward();
            window.onpopstate = function () {
                window.history.go(1);
            };
        });
    },

    isRouteActive: (routeName, exceptions = []) => {
        let routes = this.$route.matched;
        let active = reactive(false);
        routes.forEach(route => {
            if (route.name === routeName) {
                active = true;
            }
        });
        routes.forEach(route => {
            if (exceptions.includes(route.name)) {
                active = false;
            }
        });
        return active;
    },

    capitalize: (value) => {
        try {
            Object.keys(value).forEach(key => {
                if (value[key] && typeof value[key] === 'string') {
                    if (!exception.includes(key) && !key.match('_id')) { //check * hash id related on the object
                        value[key] = (value[key]) ? String(value[key]).toUpperCase() : '';
                    }
                } else {
                    value[key] = appComposables.capitalize(value[key]);
                }
            });
        } catch (e) {
            return value;
        }
        return value;
    },

    deCapitalize: action => {
        let newValue = {};
        try {
            Object.keys(value).forEach(key => {
                if (!exception.includes(key) &&
                    !(typeof value[key] === 'number') && !key.match('_id')) {
                    if (typeof value[key] === 'string') {
                        newValue[key] = (value[key]) ? String(value[key]).toLowerCase() : '';
                    } else if (value[key] === null) {
                        newValue[key] = '';
                    } else {
                        newValue[key] = this.deCapitalizeProperties(value[key]);
                    }
                } else {
                    newValue[key] = value[key];
                }
            });
        } catch (e) {
            return newValue;
        }
        return newValue;
    },

    /**
     * Convert object of data into snake case recursively
     */
    toSnakeCase: (obj) => __.transform(obj, (acc, value, key, target) => {
        const snakeKey = __.isArray(target) ? key : __.snakeCase(key);
        acc[snakeKey] = __.isObject(value) ? appComposables.toSnakeCase(value) : value;
    }),

    toCamelCase: (obj) => __.transform(obj, (acc, value, key, target) => {
        const snakeKey = __.isArray(target) ? key : __.camelCase(key);
        acc[snakeKey] = __.isObject(value) ? appComposables.toCamelCase(value) : value;
    }),

    objectParser: (obj, toCamelCase = false) => __.transform(obj, (acc, value, key, target) => {
        let objParse = toCamelCase ? __.camelCase(value) : __.snakeCase(value);

        const snakeKey = __.isArray(target) ? key : objParse;
        acc[snakeKey] = __.isObject(value) ? appComposables.objectParser(value) : value;
    }),

    ucFirst(word = null, separator = '_') {
        if (word && typeof word === 'string') {
            return word.split(separator).map((item) => {
                const firsLetter = item.trim()[0];
                if (firsLetter) {

                return firsLetter !== undefined ? firsLetter.toUpperCase() + item.slice(1) : '';
                }
                return ''
            }).join(' ');
        }
        return '';
    },

    ucFirstEachWord(sentence = '', separator = ' ') {
        if (typeof sentence !== 'string' || sentence.trim() === '') {
            return '';
        }

        return sentence.split(separator)
            .map(word => {
                const firstLetter = word.charAt(0).toUpperCase();
                const restOfWord = word.slice(1);
                return firstLetter + restOfWord;
            })
            .join(separator);
    },

    lcFirstEachWord(sentence = '', separator = ' ') {
        if (typeof sentence !== 'string' || sentence.trim() === '') {
            return '';
        }

        return sentence.split(separator)
            .map(word => {
                const firstLetter = word.charAt(0).toLowerCase();
                const restOfWord = word.slice(1);
                return firstLetter + restOfWord;
            })
            .join(separator);
    },


    goBack: async () => {
        window.history.go(-1);
        // history.back();
    },

    closeModal: async (modal) => {
        modal = bootstrap.Modal.getInstance(document.getElementById(modal));
        if (modal) {
            await nextTick(() => {
                modal.hide();
            });
        }
    },
    showModal: async (modal) => {
        modal = new bootstrap.Modal(`#${modal}`);
        if (modal) {
            await nextTick(() => {
                modal.show();
            });
        }
    },

    showTooltip: async () => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
    },

    /**
     * Check the object property if the value is "" string literals
     * if true remove property from the object
     */
    payloadParser: (payload) => {
        for (const property in payload) {
            if (__.isEmpty(property)) {
                delete payload.property;
            }
        }
    },

    /**
     * @data
     */
    encryptValue: (data) => {
        if (data) {
            const key = CryptoJS.PBKDF2(keyValue, 'salt', {
                keySize: 256 / 32,
                iterations: 100,
            });
            const iv = CryptoJS.enc.Utf8.parse(ivKey); // Convert string to WordArray
            const encrypted = CryptoJS.AES.encrypt(data, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
            });
            return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
        }
    },

    /**
     * @data
     */
    decryptData: (data) => {
        if (data) {
            const key = CryptoJS.PBKDF2(keyValue, 'salt', {
                keySize: 256 / 32,
                iterations: 100,
            });
            const iv = CryptoJS.enc.Utf8.parse(ivKey);
            const decrypted = CryptoJS.AES.decrypt({ciphertext: CryptoJS.enc.Hex.parse(data)}, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
            });
            return decrypted.toString(CryptoJS.enc.Utf8);
        }
        return '';
    },
    updateGlobalCommonKeys: (resource, payload, action = 'create') => {
        const useGlobalCommonKeys = null;
        switch (action) {
            case 'create':
                console.log('create global common keys');
                break;
            case 'update':
                console.log('update global common keys');
                break;
        }
    },

    /**
     * @param s
     * e.g one hundred thousand
     */
    toWords(s) {
        let th = ['', 'thousand', 'million', 'billion', 'trillion'];
        let dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        let tn = [
            'ten',
            'eleven',
            'twelve',
            'thirteen',
            'fourteen',
            'fifteen',
            'sixteen',
            'seventeen',
            'eighteen',
            'nineteen'];
        let tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
        s = s.toString();
        s = s.replace(/[\, ]/, '');
        if (s != parseFloat(s)) return 'not a number';
        let x = s.indexOf('.');
        if (x === -1) x = s.length;
        if (x > 15) return 'too big';
        let n = s.split('');
        let str = '';
        let sk = 0;
        for (let i = 0; i < x; i++) {
            if ((x - i) % 3 == 2) {
                if (n[i] == '1') {
                    str += tn[Number(n[i + 1])] + ' ';
                    i++;
                    sk = 1;
                } else if (n[i] != 0) {
                    str += tw[n[i] - 2] + ' ';
                    sk = 1;
                }
            } else if (n[i] != 0) {
                str += dg[n[i]] + ' ';
                if ((x - i) % 3 == 0) str += 'hundred ';
                sk = 1;
            }
            if ((x - i) % 3 == 1) {
                if (sk) str += th[(x - i - 1) / 3] + ' ';
                sk = 0;
            }
        }
        if (x != s.length) {
            let y = s.length;
            str += 'point ';
            for (let i = x + 1; i < y; i++) str += dg[n[i]] + ' ';
        }
        return str.replace(/\s+/g, ' ');
    },

    /**
     *@param val
     *@return string
     * e.g one hundred thousand & 53/100
     */
    toWordsWithDecimals(val) {
        val = appComposables.toGetTwoDigitsAfterDecimalPoint(val);
        let nums = val.toString().split('.');
        let whole = this.toWords(nums[0]);
        let notValid = 'Not a valid amount';

        let words = whole.split(" "); // Split the string into an array of words

        for (var i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1); // Capitalize the first letter of each word
        }

        whole = words.join(" ");

        console.log('value', parseInt(val));

        switch (true) {
            case val.length > 15:
                return 'Too big';
            case (parseInt(nums[0]) === 0 || !nums[0]) && (parseInt(nums[1]) > 0 && nums[1].length === 1):
                return nums[1] + '0/100' + ' ' + 'only';
            // if no whole number or 0 is inputted, AND there are decimal numbers, it will return decimal only
            case (parseInt(nums[0]) === 0 || !nums[0]) && parseInt(nums[1]) > 0:
                return nums[1] + '/100' + ' ' + 'only';
            // if there is a whole number AND there is no decimal or 0 is inputted, it will return whole number only
            case nums[0] > 0 && (parseInt(nums[1]) === 0 || !nums[1]):
                return whole + 'only';
            case nums[0] > 0 && nums[1].length === 1:
                return whole + '& ' + nums[1] + '0/100' + ' ' + 'only';
            // if there is a whole number AND there are decimal numbers, it will return the whole number with the decimal
            case nums[0] > 0 && nums[1] > 0:
                return whole + '& ' + nums[1] + '/100' + ' ' + 'only';
            default:
                return notValid;
        }
    },

    convertToMoney(value) {
        value = String(value)
        const decimalCount = value.split('.').length - 1;
        if (decimalCount > 1) {
            value = value.substring(0, value.lastIndexOf('.'));
        }

        // only get two digits after decimal point
        value = appComposables.toGetTwoDigitsAfterDecimalPoint(value);

        // add commas for thousands separator
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return value;
    },

    toFormatAmount(inputField) {
        inputField = document.getElementById(inputField);
        inputField.addEventListener('input', function (event) {

            let cursorPosition = event.target.selectionStart;
            let value = event.target.value;
            event.target.value =  appComposables.convertToMoney(value);
            let diff = event.target.value.length - value.length;
            event.target.selectionStart = cursorPosition + diff;
            event.target.selectionEnd = cursorPosition + diff;
        });
    },

    toGetTwoDigitsAfterDecimalPoint(amount) {
        amount = String(amount)
        // Remove commas from the amount
        amount = amount.replace(/[^\d.]/g, '');
        const decimalIndex = amount.indexOf('.');
        if (decimalIndex !== -1) {
            const decimalPart = amount.substring(decimalIndex + 1);
            // Remove any non-digit characters from the decimal part
            amount = amount.substring(0, decimalIndex + 1) + decimalPart.replace(/\D/g, '');
            // Ensure that the decimal part has at most two digits
            if (decimalPart.length > 2) {
                amount = amount.substring(0, decimalIndex + 2);
            }
        }
        // console.log('amount', amount);
        return amount;
    },

    toFormatDecimals(amount) {
        // ensure that only 1 decimal point gets taken
        const decimalPointCount = amount.split('.').length - 1

        // digits after decimal
        const decimalIndex = amount.indexOf('.');

        if (decimalIndex !== -1) {
            const decimalPart = amount.substring(decimalIndex + 1);
            // remove any non-digit characters from the decimal part
            amount = amount.substring(0, decimalIndex + 1) + decimalPart.replace(/\D/g, '');

            // ensure that the decimal part has at most two digits
            if (decimalPart.length > 2) {
                amount = amount.substring(0, decimalIndex + 3); // Retain two decimal digits
            } else if (decimalPart.length === 1) {
                amount += '0'; // add '0' if there's only one decimal digit
            } else if (decimalPointCount === 1 && decimalPart.length === 0) {
                amount += '00'; // if there's a decimal point but no decimal digits, add 00 at the end
            }
        } else {
            amount += '.00'; // add '00' if there's no decimal part but there's no decimal point
        }
        return amount
    },

    toIntlNumberFormat(number) {
        return new Intl.NumberFormat().format(number);
    },

    toFormatCheckNumber(inputField){
        inputField = document.getElementById(inputField);
        return value = inputField.value.replace(/[^0-9-/]/g, '');
    },

    /**
     *@param obj remove obj property from the object if null or true under the conditions
     */
    clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        }
        return obj
    },

    isObjectComplete(obj, objectFields = [], eitherOrFields = []) {
        //const fields = objectFields.length > 0 ? objectFields : obj;
        let hasEmptyProperty  = false;
        let hasOtherEmpty  = false;

        // Check if any required fields are empty in objFields
        if (Array.isArray(obj)) {
            hasEmptyProperty = obj.some((val)=>
                objectFields.some(key =>
                    val.hasOwnProperty(key) && (val[key] == null || val[key] === '')
                )
            );
        } else {
            hasEmptyProperty = objectFields.some(field =>
                obj.hasOwnProperty(field) && (obj[field] == null || obj[field] === '')
            );
        }

        if (Array.isArray(eitherOrFields) && eitherOrFields.length === 2) {
           const field1 = obj[eitherOrFields[0]];
            const field2 = obj[eitherOrFields[1]];
            console.log(field1, field2);
            hasOtherEmpty = appComposables.isZeroValue(field1) && appComposables.isZeroValue(field2)
        }

        return !hasEmptyProperty && !hasOtherEmpty;
    },

    showCurrentDateTimeDay() {
        let dateString = new Date().toString();

        // Split the date string by space
        const dateArray = dateString.split(' ');

        // Take only the first five strings
        return dateArray.slice(0, 5).join(' ');
    },

    /**
     * @param e event
     */
    excludeSpecialCharacters(e) {
        console.log('ping from composable', e.target.value.which)
        // let regex = /^[a-z -]+$/i;
        // let key = String.fromCharCode(!e.key.charCode ? e.key.which : e.key.charCode);
        // console.log('ping from composable',key)
        // if (!regex.test(key)) {
        //   e.preventDefault();
        //   return false;
        // }
    },

    hideSidenav() {
        const sidebarToggle = document.body.querySelector('#sidebarToggle');
        const hideSidenavClass = document.body.querySelector('.app-sidenav-toggled');
        if (sidebarToggle) {
            if (localStorage.getItem('app|sidebar-toggle') === 'true') {
                document.body.classList.add('app-sidenav-toggled');
            }

            if (!hideSidenavClass) {
                document.body.classList.add('app-sidenav-toggled');
            }

            // Set the value in localStorage after event listener setup
            localStorage.setItem('app|sidebar-toggle', true);
        }
    },

    autofocusNextInput(nextInput) {
        const inputField = document.getElementById(nextInput);
        if (inputField) {
            inputField.focus();
        }
    },

    handleAutofocusOnModal(id) {
        document.activeElement.blur();
        setTimeout(() => {
            appComposables.autofocusNextInput(id);
        }, 500);
    },

    getTotalAmount(objEntries,type = 'debit_amount'){
        let totalAmount = 0;
        let amount = 0;
        if (objEntries?.length > 0) {
            //objEntries?.value?.entries.filter((entry, index) => {
            //    // entry = toRaw(entry)
            //    entry.originalIndex = index;
            //    return !entry.is_trashed;
            //}) ?? [];
            objEntries.map(entry => {
                amount += entry[type] ? parseFloat(entry[type]) : 0;
            })
        }
        totalAmount = this.convertToMoney(amount);
        let formatedValue = this.toFormatDecimals(totalAmount);
        let noCommaValue = formatedValue.replace(/,/g, '');
        return parseFloat(noCommaValue).toFixed(2)
    },

    toNumberFormat(number) {
     number = number.replace(/,/g, ''); //
     return parseFloat(parseFloat(number).toFixed(2));
    },

    /**
     * @param transactionAmount
     * @param totalDebit
     * @param totalCredit
     * @param transactionType
     */

    isZeroTotal(transactionAmount = null,totalAmount=null,totalDebit,totalCredit,transactionType='cv') {
        totalDebit =  isNaN(totalDebit) ?  this.toNumberFormat(totalDebit) : totalDebit;
        totalCredit = isNaN(totalCredit) ?  this.toNumberFormat(totalCredit) : totalCredit;
        totalAmount = isNaN(totalAmount) ?  this.toNumberFormat(totalAmount) : totalAmount;
        transactionAmount = isNaN(transactionAmount) ?  this.toNumberFormat(transactionAmount) : transactionAmount;

        let zeroTotalAmount = 0;

        if(transactionType !== 'jv') {
          zeroTotalAmount = (transactionAmount - totalAmount) - (totalDebit - totalCredit);
        }
        else {
          zeroTotalAmount = totalDebit - totalCredit
        }
        // console.log(totalAmount,transactionAmount,totalDebit,totalCredit);
        return zeroTotalAmount === 0;
    },

    /**
     * @param value
     */
    isEmptyValue(value) {
      return value === undefined || value === null || value === '' ||
        (typeof value === 'number' && isNaN(value)) ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.length === 0) ||
        (Array.isArray(value) && value.length === 0)
    },

    isZeroValue(value) {
      return value === 0 || value === '' || value === '0.00' || value === '.00' || value === '0.0';
    },
    formatDate(date, format){
      const yyyy = date.getFullYear();
      const yy = String(yyyy).slice(-2);
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const dd = String(date.getDate()).padStart(2, '0');

      return format.replace('YYYY', yyyy).replace('YY', yy).replace('MM', mm).replace('DD', dd);
    },
  getAmountVariance(subTotalDebit, subTotalCredit) {
    if (isNaN(subTotalDebit) || isNaN(subTotalCredit)) {
      console.error("Please provide a valid number")
    }
    const variance =  Math.abs((subTotalDebit - subTotalCredit))
    return variance;
  },

  getTotalFromSubTotal(subTotalDebit, subTotalCredit) {
    const totalDebit = subTotalDebit;
    const totalCredit = subTotalCredit;

    if (appComposables.getAmountVariance(subTotalDebit, subTotalCredit) === 0) {
      return (Math.abs(subTotalDebit) + Math.abs(subTotalCredit)) / appComposables.getTotalFromSubTotal.length;
    }
    return '0.00';
  },

  returnPayor(payorList,payorId){
    const payor = payorList.find(payor => payor.hash_id === payorId);
    if (payor) {
      // If payor is a company, return company name
      if (payor.is_company === 1) {
        return payor.company_name;
      } else {
        // If payor is an individual, construct the name
        return [
          appComposables.ucFirst(payor.last_name), ', ',
          appComposables.ucFirst(payor.first_name), ' ',
          appComposables.ucFirst(payor.middle_name)?.substring(0, 1) ?? ''
        ].join('');
      }
    } else {
      return null;
    }
  },

  deepCopy(object){
    return JSON.parse(JSON.stringify(object));
  }
};

export const loaderComposable = {
    requestStatus: ref(false),
    setStatus(val) {
        this.requestStatus = val;
    },
    getStatus() {
        return this.requestStatus;
    },
};

/**
 * use for common alert actions messages
 */
export const alertContext = {
    success: async (context, actionType) => {
        return await appAlert().success(`${actions[actionType]}`,`${context}`);
    },
    error: (context, actionType) => {
        return appAlert().success(`${actions[actionType]}`, `${context}`);
    },
};

export default {
    appComposables,
    loaderComposable,
    alertContext,
    CREATING,
    UPDATING,
    DELETING,
};

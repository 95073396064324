<template>
  <nav class="app-topnav navbar navbar-expand navbar-light bg-light">
    <!-- Navbar Brand-->
    <router-link :to="{name:'Home'}" href="#">
      <div style="max-width: 500px;">
        <img style="height: 40px;" src="@/assets/krbs.logo.png" alt="app-logo"
             class="d-lg-none img-fluid ms-2 ps-0 mw-100">
        <img src="@/assets/krbs-accounting-logo.png" alt="app-logo"
             class="d-none d-lg-block img-fluid ms-2 ps-0 mw-100">
      </div>
    </router-link>

    <div class="d-flex ms-2" style="height: 31px">
      <div class="vr"></div>
    </div>
    <!-- Sidebar Toggle-->
    <button class="btn btn-link btn-sm order-1 order-lg-0 me-3 me-lg-0" id="sidebarToggle" href="#!">
      <font-awesome-icon class="fa-xl" icon="bars"/>
    </button>
    <!--  Left Links -->
    <ul class="d-none d-md-flex navbar-nav me-3 ms-md-0 ms-lg-4 text-nowrap" style="cursor: pointer;">

      <li class="nav-link" @click="newTransaction('cv')">
          <font-awesome-icon icon="file-alt" class="me-1"/>
          <small>Check Disbursement</small>
      </li>

      <li class="nav-link" @click="newTransaction('cr')">
          <font-awesome-icon icon="file-alt" class="me-1"/>
          <small>Cash Receipt</small>
      </li>
      <li class="nav-link" @click="newTransaction('jv')">
          <font-awesome-icon icon="file-alt" class="me-1"/>
          <small>Journal Voucher</small>
      </li>
    </ul>
    <!-- Right Links -->
    <ul class="navbar-nav ms-auto me-0 me-md-2 align-items-center">
      <li class="nav-item">
        <a class="nav-link mt-1" href="#">
          <font-awesome-icon class="fa-2x" icon="bell" style="height: 24px;"/>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link">
          <img src="@/assets/avatars/avatar1.jpg" alt="Avatar" class="avatar">
        </a>
      </li>

      <!--  -->
      <li class="nav-item dropdown">
        <a class="nav-link avatar-link align-middle d-flex align-items-center gap-2 transition pe-md-0"
           id="navbarDropdown" href="#" role="button"
           data-bs-toggle="dropdown" aria-expanded="false">

          <div class="lh-sm w-100 text-end">
            <small class="fw-bold d-block p-0 text-nowrap">{{[appComposables.ucFirst(userAuth.last_name), ', ', appComposables.ucFirst(userAuth.first_name), ' ', appComposables.ucFirst(userAuth.middle_name)?.substring(0, 1) ?? ''].join('')}} </small>
            <!--<small class="text-secondary text-nowrap">-->
            <!--System Admin-->
            <!--</small>-->
          </div>
          <font-awesome-icon icon="angle-down"/>
        </a>
        <ul class="dropdown-menu dropdown-menu-end" style="font-size: 14px !important;"
            aria-labelledby="navbarDropdown">
          <li>
            <a class="dropdown-item">Profile</a>
          </li>
          <li>
            <a class="dropdown-item">Activity Log</a>
          </li>
          <li>
            <hr class="dropdown-divider"/>
          </li>
          <li>
            <a class="dropdown-item" @click="formMethods.logout">Logout</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
<script>
import {nextTick, onMounted, onUnmounted} from 'vue';
import {onBeforeRouteLeave, useRouter} from 'vue-router';
import {appAlert} from '@/composables/alert.composables';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {authModule} from '@/store/auth.module';
import __ from 'lodash';
import {appComposables} from "@/composables/global.composables";

export default {
  name: 'AppHeader',
  components: {FontAwesomeIcon},
  setup() {
    const router = useRouter();
    const userAuthModule = authModule();
    const {getUserAuth:userAuth} = authModule()


    const formMethods = {
      userProfile() {
        nextTick(() => {
          console.log('getting user profile')
        })
      },
      activityLog() {
        nextTick(() => {
          console.log("getting user activity log")
        })
      },

      async logout() {
        if (await appAlert().question('logout')) {
          userAuthModule.logout().then((r) => {
            if (r ===true) {
              nextTick(() => {
                window.localStorage.clear();
                if (__.isEmpty(localStorage)) {
                  appAlert().success('Logout');
                  router.push(
                    {
                      name: 'Login',
                    },
                  );
                }
              });
            }
          });
        }
      },
    };
    function newTransaction(type) {
      localStorage.removeItem('trn-hash-id');
      router.push({
        name: 'AddTransaction',
        query: { compose: '' },
        params: { type: type }
      })
      //.then(() => window.location.reload())
    }
    onMounted(() => {
    });

    onUnmounted(() => {
      userAuthModule.$reset();
    });

    return {
      formMethods,
      appComposables,
      userAuth,
      newTransaction
    };
  },
};
</script>

<style scoped>
.avatar {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border-radius: 30%;
}

.navbar-nav > li > a {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>

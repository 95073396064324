<template>
  <router-view/>
</template>
<style scoped>
</style>
<script>
import {authModule} from '@/store/auth.module';
import {storeToRefs} from 'pinia';
import {nextTick, onBeforeMount, onMounted} from 'vue';
import {useRouter} from 'vue-router';

export default {
  name: 'App',
  setup(){
    //const useAuthModule = authModule()
    //const router = useRouter()
    //const {
    //  getToken,
    //} = storeToRefs(useAuthModule)
    //
    //onMounted(()=>{})
    //onBeforeMount(()=>{
    //  nextTick(()=>{
    //    if (__.isEmpty(useAuthModule.getToken)){
    //      router.push({name : 'App'})
    //      useAuthModule.$reset();
    //      window.localStorage.clear();
    //    }else {
    //      router.push({name : 'Home'})
    //    }
    //  })
    //})
  }
}
</script>

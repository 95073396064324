<template>
    <main style="width: 100%;">
        <router-view />
    </main>
</template>
<script>
import { onMounted, onUnmounted } from 'vue'

export default {
  name: 'AppMain',
  components: {},
  setup () {
    onMounted(() => {})
    onUnmounted(() => {})
    return {}
  },
}
</script>

<style scoped>

</style>
